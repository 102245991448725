import https from "./https"

const partnerStaffApi = {
  getPartnerStaffs (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/partner-staffs'

    return https.get(url, { params })
  },
  getPartnerList (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/partner-staffs/partner-list'

    return https.get(url, { params })
  },
  setPartners (organization, staffId, params) {
    const url = 'admin/organizations/' + organization.id  + '/partner-staffs/' + staffId + '/set-partners'

    return https.put(url, params)
  }
}

export default partnerStaffApi
